<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <div class="p-col-12 mb-2">
                <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                <Toolbar>
                    <template #start>
                        <Dropdown v-model="state.search.clientIdS" :options="state.userMerchants" optionLabel="name" optionValue="id" placeholder="--Merchant--" class="w-full md:w-14rem ml-2" />
                        <Calendar v-model="state.search.dateS" :showIcon="true" dateFormat="dd-mm-yy" class="w-full md:w-14rem ml-4" />
                    </template>
                    <template #end>
                        <Button label="ค้นหา" icon="pi pi-search" class="p-button-info" @click="getData()" />
                    </template>
                </Toolbar>

                <DataTable
                    :value="state.depostsList"
                    stripedRows
                    :paginator="true"
                    :rows="20"
                    :totalRecords="state.totalRecords"
                    :loading="state.loading"
                    :rowsPerPageOptions="[20, 50, 100, 200, 500]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    dataKey="id"
                    class="p-datatable-sm"
                >
                    <Column field="no" :header="$t('common.number')" :style="{ width: '100px', textAlign: 'center' }" headerClass="center">
                        <template #body="slotProps">
                            {{ slotProps.index + 1 }}
                        </template>
                    </Column>
                    <Column field="name" header="Name" :style="{ textAlign: 'center' }" headerClass="center"></Column>
                    <Column field="refId" header="refId" :style="{ textAlign: 'center' }" headerClass="center"></Column>
                    <Column field="createdOn" header="Created On" :style="{ textAlign: 'center' }" headerClass="center">
                        <template #body="{ data }">
                            {{ useDateFormat(data.createdOn, 'YYYY-MM-DD HH:mm').value }}
                        </template>
                    </Column>
                    <Column field="completedOn" header="Completed On" :style="{ textAlign: 'center' }" headerClass="center">
                        <template #body="{ data }">
                            <span v-if="data.completedOn != null">
                                {{ useDateFormat(data.completedOn, 'YYYY-MM-DD HH:mm').value }}
                            </span>
                        </template>
                        <template #footer><span class="font-bold">รวม</span> </template>
                    </Column>
                    <Column field="amount" header="Amount" :style="{ textAlign: 'right' }" headerClass="right">
                        <template #body="slotProps">
                            <span class="font-bold" style="color: blue">
                                {{ formatNumber(slotProps.data.amount) }}
                            </span>
                        </template>
                        <template #footer
                            ><span class="font-bold" style="color: blue">{{ formatNumber(state.totalAmount) }}</span>
                        </template>
                    </Column>
                    <template #footer> In total there are {{ formatInt(state.totalRecords) }} item. </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useAuthStore } from '@/store/auth';
import DepositsService from '@/service/DepositsService';
import ComboService from '@/service/ComboService';
import { formatInt, formatNumber } from '@/common/Utils';
import { useDateFormat } from '@vueuse/shared';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const authUserStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();
const auth = computed(() => authUserStore.user);
const state = reactive({
    dialog: false,
    errorMessageH: '',
    errorMessage: '',
    loading: false,
    role: '',
    search: { clientIdS: '', dateS: new Date() },
    userMerchants: [],
    depostsList: [],
    totalRecords: 0,
    totalAmount: 0,
});

const getData = async () => {
    try {
        state.loading = true;
        const response = await DepositsService.findDepositsByDayList(state.search.clientIdS, useDateFormat(state.search.dateS, 'YYYY-MM-DD').value);
        state.depostsList = response.data.data;
        state.totalRecords = response.data.total;
        state.totalAmount = response.data.totalAmount;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const getCombo = async () => {
    try {
        state.loading = true;
        const response = await ComboService.findUserMerchantsList();
        state.userMerchants = response.data.data;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

onMounted(() => {
    state.role = auth.value.role;
    //
    getCombo();
    getData();
});
</script>
